<template>
    <div class="banner">
        <img
            :src="srcDesktop"
            :class="{
                'px-8': !noPadding
            }"
            class="d-none d-sm-block"
            width="100%"
        >
        <img
            :src="srcMobile"
            class="d-sm-none"
            width="100%"
        >
        <h1>
            <slot>
                {{ title }}
            </slot>
        </h1>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: () => '',
        },
        srcDesktop: {
            type: String,
            default: () => '',
        },
        srcMobile: {
            type: String,
            default: () => '',
        },
        noPadding: {
            type: Boolean,
            default: () => false,
        },
    },
};
</script>

<style lang="scss" scoped>
.banner {
    position: relative;
}
h1 {
    letter-spacing: 6px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    white-space:pre-wrap;
    width: 80%;
    text-align: center;
}
</style>

<template>
    <Layout class="registration">
        <template #banner>
            <Banner
                :title="title"
                :src-desktop="require('@/assets/registration/banner.jpg')"
                :src-mobile="require('@/assets/registration/banner-mobile.jpg')"
                no-padding
            />
        </template>
        <router-view />
    </Layout>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        title() {
            return this.$route.meta.title;
        },
    },
};
</script>

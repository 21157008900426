<template>
    <div class="competition-notice">
        <h1 class="text-center pa-sm-16 pa-5 pl-10">
            競賽須知
        </h1>
        <h2 class="title d-flex align-center justify-center pb-sm-5 pb-3 pl-10">
            <span>說明事項</span>
            &nbsp;
            <img
                src="../assets/V.png"
                width="50"
            >
        </h2>
        <div class="d-flex justify-center">
            <template v-if="settings['說明事項']">
                <pre class="list pa-sm-16 pa-5 pl-10">{{ settings['說明事項'] }}</pre>
            </template>
            <template v-else>
                <ol class="list pa-sm-16 pa-5 pl-10">
                    <li>
                        考後各科獨立讀卡閱卷，提供各科成績及總分排名、PR值給家長參考。
                    </li>
                    <li>
                        每人製作一份成績報告書，內含各科能力指標分析圖、學力分析報告。
                    </li>
                </ol>
            </template>
        </div>
        <h2 class="title d-flex align-center justify-center pb-sm-5 pb-3 pl-10">
            <span>應試規則</span>
            &nbsp;
            <img
                src="../assets/V.png"
                width="50"
            >
        </h2>
        <div class="d-flex justify-center">
            <template v-if="settings['應試規則']">
                <pre class="list pa-sm-16 pa-5 pl-10">{{ settings['應試規則'] }}</pre>
            </template>
            <template v-else>
                <ol class="list pa-sm-16 pa-5 pl-10">
                    <li>本競賽採用電腦閱卷，當天應考請務必攜帶2B鉛筆、橡皮擦。</li>
                    <li>
                        考生請勿攜帶電子用品（智慧型手錶、手機）進入試場，違規或不聽勸該科不予計分。
                    </li>
                    <li>
                        為維護檢定及競賽之公平性，未攜帶身分證明者（健保卡），考試成績不列入排名。
                    </li>
                    <li>考試期間家長禁止進入考場管制區域，以免影響考生作答。</li>
                    <li>
                        建議家長與孩子事先約定好考試後的等候地點，以利接送考生。
                    </li>
                </ol>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('mSetting', ['settings']),
    },
};
</script>

<style lang="scss" scoped>
.title {
    height: 100px;
    position: relative;
    color: #fff;
    background-image: url("../assets/title.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: 600px) {
        height: 200px;
    }
}
.list {
    width: 90vw;
    max-width: 1000px;
    margin: auto;
}
</style>

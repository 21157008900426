import _ from 'lodash';
import axios from 'axios';
import APIFormData from '@/api/libs/form-data';
import RequestCache from '@/api/libs/request-cache';

export default class ResourceAPI {
    constructor(name, getDefaultOptions) {
        this.name = name;
        this.getDefaultOptions = getDefaultOptions;
        this.cache = new RequestCache(name, this.SubKeys);
    }

    toConfig(options) {
        return _.merge(this.getDefaultOptions(), options);
    }

    // eslint-disable-next-line class-methods-use-this
    get SubKeys() {
        return {
            PrivateList: 'private-list',
            PublicList: 'public-list',
        };
    }

    async PrivateList(options) {
        return axios.get(`/api/${this.name}/pvt/list`, this.toConfig(options));
    }

    async PrivateFindResource(options) {
        return axios.get(`/api/${this.name}/pvt/resource/find`, this.toConfig(options));
    }

    async PrivateListWithCache(options) {
        return this.cache.run(this.PrivateList.bind(this), this.SubKeys.PrivateList, options);
    }

    async PrivateCreateResource(data, options) {
        this.cache.clean();
        //
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/pvt/resource`, body, this.toConfig(options));
    }

    async PrivateFetchResource(id, options) {
        return axios.get(`/api/${this.name}/pvt/resource/${id}`, this.toConfig(options));
    }

    async PrivateUpdateResource(id, data, options) {
        this.cache.clean();
        //
        const body = await APIFormData.toBody(data);
        return axios.put(`/api/${this.name}/pvt/resource/${id}`, body, this.toConfig(options));
    }

    async PrivateDeleteResource(id, options) {
        this.cache.clean();
        //
        return axios.delete(`/api/${this.name}/pvt/resource/${id}`, this.toConfig(options));
    }

    async PrivateMoveResource(id, direction, unit, options) {
        this.cache.clean();
        //
        // eslint-disable-next-line max-len
        return axios.patch(`/api/${this.name}/pvt/resource/${id}/move/${direction}/${unit}`, {}, this.toConfig(options));
    }

    async PrivateTogglePropResource(id, prop, options) {
        this.cache.clean();
        //
        return axios.patch(`/api/${this.name}/pvt/resource/${id}/toggle/${prop}`, {}, this.toConfig(options));
    }

    async PublicCreateResource(data, options) {
        this.cache.clean();
        //
        const body = await APIFormData.toBody(data);
        return axios.post(`/api/${this.name}/pub/resource`, body, this.toConfig(options));
    }

    async PublicList(options) {
        return axios.get(`/api/${this.name}/pub/list`, this.toConfig(options));
    }

    async PublicListWithCache(options) {
        return this.cache.run(this.PublicList.bind(this), this.SubKeys.PublicList, options);
    }

    async PublicFetchResource(id, options) {
        return axios.get(`/api/${this.name}/pub/resource/${id}`, this.toConfig(options));
    }
}

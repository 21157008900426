/* eslint-disable object-curly-newline */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import _ from 'lodash';
import axios from 'axios';
import { v4 as uuid4 } from 'uuid';

export default class APIFormData {
    constructor(data, resources = []) {
        this.raw = _.cloneDeep(data);
        this.resources = resources;
    }

    static async parsing(instance) {
        const list = [];
        const files = [];
        if (instance instanceof APIFormData) {
            const { raw, resources } = instance;
            const others = _.omit(instance, ['raw', 'resources']);
            for (const resource of resources) {
                const { urls, ext } = resource;
                for (const url of urls) {
                    if (!/^(blob|https?)/.test(url)) {
                        list.push(url);
                    } else {
                        try {
                            const res = await axios.get(url, { responseType: 'blob' });
                            files.push({
                                blob: url,
                                filename: `${uuid4().slice(-12)}.${ext(res.data.type)}`,
                                origin: res.data,
                            });
                        } catch { /* empty */ }
                    }
                }
            }
            return { raw, list, files, others };
        }
        return { raw: instance, list, files, others: {} };
    }

    static async toBody(instance) {
        // eslint-disable-next-line no-undef
        const form = new FormData();
        const { raw, list, files, others } = await APIFormData.parsing(instance);
        //
        form.append('data', JSON.stringify(raw));
        form.append('keepList', JSON.stringify(list));
        _.forEach(files, (file) => {
            form.append(file.blob, file.origin, file.filename);
        });
        _.forEach(others, (value, key) => {
            form.append(key, JSON.stringify(value));
        });
        return form;
    }
}

/* eslint-disable no-undef */
import _ from 'lodash';

export default class RequestCache {
    constructor(name, keys) {
        this.name = name;
        this.keys = keys;
    }

    async run(api, key, options) {
        if (sessionStorage) {
            const storeKey = `${this.name}-${key}`;
            const cache = sessionStorage.getItem(storeKey);
            if (!cache) {
                const res = await api(options);
                if (res.data) {
                    sessionStorage.setItem(storeKey, JSON.stringify(res.data));
                }
                return res;
            }
            return { data: JSON.parse(cache) };
        }
        return api(options);
    }

    async clean() {
        _.forEach(this.keys, (key) => {
            if (sessionStorage) {
                const storeKey = `${this.name}-${key}`;
                sessionStorage.removeItem(storeKey);
            }
        });
    }
}

<template>
    <Layout>
        <template #banner>
            <Banner
                :title="title"
                :src-desktop="require('@/assets/search/banner.png')"
                :src-mobile="require('@/assets/search/banner-mobile.png')"
            />
        </template>
        <v-card
            class="ma-auto"
            style="max-width: 800px; padding: 10%"
        >
            <h2 class="text-center">
                查無資料
            </h2>
        </v-card>
        <br>
        <v-btn
            dark
            rounded
            class="d-block ma-auto px-10"
            :style="{
                background: 'var(--color-primary-gradient)',
            }"
            @click="$router.go(-1)"
        >
            回上頁
        </v-btn>
    </Layout>
</template>

<script>
export default {
    computed: {
        title() {
            return this.$route.meta.title;
        },
    },
};
</script>

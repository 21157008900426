import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Report from '@/views/Report.vue';
import CompetitionNotice from '@/views/CompetitionNotice.vue';
import Regulations from '@/views/Regulations.vue';
import RegulationsActivity from '@/views/RegulationsActivity.vue';
import RegulationsScope from '@/views/RegulationsScope.vue';
import RegulationsRefund from '@/views/RegulationsRefund.vue';
import Search from '@/views/Search.vue';
import PersonalSearch from '@/views/PersonalSearch.vue';
import PersonalSearchList from '@/views/PersonalSearchList.vue';
import GroupSearch from '@/views/GroupSearch.vue';
import GroupSearchList from '@/views/GroupSearchList.vue';
import HistorySearch from '@/views/HistorySearch.vue';
import NotExist from '@/views/NotExist.vue';
import AdmitCardSearch from '@/views/AdmitCardSearch.vue';
import AdmitCardSearchList from '@/views/AdmitCardSearchList.vue';
import GradesInquiries from '@/views/GradesInquiries.vue';
import GradesInquiryList from '@/views/GradesInquiryList.vue';
import PersonalPaymentSearch from '@/views/PersonalPaymentSearch.vue';
import Statistics from '@/views/Statistics.vue';
import Contact from '@/views/Contact.vue';
import Registration from '@/views/Registration.vue';
import RegistrationGroup from '@/views/RegistrationGroup.vue';
import RegistrationPersonal from '@/views/RegistrationPersonal.vue';
import RegistrationPersonalConfirm from '@/views/RegistrationPersonalConfirm.vue';
import RegistrationPersonalSuccess from '@/views/RegistrationPersonalSuccess.vue';
import RegistrationPersonalPayComplete from '@/views/RegistrationPersonalPayComplete.vue';
import Question from '@/views/Question.vue';
import HistoryExam from '@/views/HistoryExam.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(
                () => {
                    resolve(
                        // eslint-disable-next-line no-nested-ternary
                        savedPosition
                            ? { savedPosition, behavior: 'smooth' }
                            : to.hash
                                ? { selector: to.hash, behavior: 'smooth' }
                                : { x: 0, y: 0 },
                    );
                },
                // eslint-disable-next-line no-nested-ternary
                to.path === from.path ? (to.hash ? 100 : 0) : to.hash ? 500 : 250,
            );
        });
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        // {
        //     path: '/test',
        //     name: 'test',
        //     component: GradesInquiries,
        // },
        {
            path: '/about',
            name: 'About',
            component: About,
        },
        {
            path: '/report',
            name: 'Report',
            component: Report,
        },
        {
            path: '/question',
            redirect: '/question/賽事資訊',
        },
        {
            path: '/question/:type',
            name: 'Question',
            component: Question,
            props: true,
        },
        {
            path: '/competition-notice',
            name: 'CompetitionNotice',
            component: CompetitionNotice,
        },
        {
            path: '/regulations',
            name: 'Regulations',
            component: Regulations,
            redirect: {
                name: 'RegulationsActivity',
            },
            children: [
                {
                    path: 'activity',
                    name: 'RegulationsActivity',
                    component: RegulationsActivity,
                },
                {
                    path: 'scope',
                    name: 'RegulationsScope',
                    component: RegulationsScope,
                },
                {
                    path: 'refund',
                    name: 'RegulationsRefund',
                    component: RegulationsRefund,
                },
            ],
        },
        {
            path: '/personal',
            component: Search,
            name: 'Personal',
            props: true,
            meta: { title: '個人報名資料及繳費查詢' },
            redirect: { name: 'PersonalSearch' },
            children: [
                {
                    path: 'search',
                    name: 'PersonalSearch',
                    component: PersonalSearch,
                    meta: { title: '個人報名資料及繳費查詢' },
                },
                {
                    path: 'list/:searchID',
                    name: 'PersonalSearchList',
                    component: PersonalSearchList,
                    props: true,
                    meta: { title: '查詢列表', permission: true },
                },
                {
                    path: 'admit-card/:searchID',
                    name: 'PersonalAdmitCardSearch',
                    component: AdmitCardSearch,
                    props: true,
                    meta: { title: '考試資訊及准考證號碼查詢', subtitle: '考生個人報名資料及繳費查詢', permission: true },
                },
                {
                    path: 'grades-inquiries/:searchID/:hash/:activity',
                    name: 'PersonalGradesInquiries',
                    component: GradesInquiries,
                    props: true,
                    meta: { title: '成績查詢', subtitle: '考生個人報名資料及繳費查詢', permission: true },
                },
                {
                    path: 'payment/:searchID/:serial',
                    name: 'PersonalPaymentSearch',
                    component: PersonalPaymentSearch,
                    props: true,
                    meta: { title: '繳費查詢', subtitle: '考生繳費查詢', permission: true },
                },
            ],
        },
        {
            path: '/group',
            component: Search,
            name: 'Group',
            props: true,
            meta: { title: '團體報名資料及團體成績查詢' },
            redirect: { name: 'GroupSearch' },
            children: [
                {
                    path: 'search',
                    name: 'GroupSearch',
                    meta: { title: '團體報名資料及團體成績查詢' },
                    component: GroupSearch,
                },
                {
                    path: 'list/:searchID',
                    name: 'GroupSearchList',
                    component: GroupSearchList,
                    props: true,
                    meta: { title: '查詢列表', permission: true },
                },
                {
                    path: 'admit-card-list/:searchID',
                    name: 'GroupAdmitCardSearchList',
                    component: AdmitCardSearchList,
                    props: true,
                    meta: { title: '考試資訊及准考證號碼查詢', subtitle: '團體報名資料及團體成績查詢', permission: true },
                },
                {
                    path: 'admit-card/:searchID/:hash',
                    name: 'GroupAdmitCardSearch',
                    component: AdmitCardSearch,
                    props: true,
                    meta: { title: '考試資訊及准考證號碼查詢', subtitle: '考生個人報名資料及繳費查詢', permission: true },
                },
                {
                    path: 'grades-inquiry-list/:searchID',
                    name: 'GroupGradesInquiryList',
                    component: GradesInquiryList,
                    props: true,
                    meta: { title: '成績查詢', subtitle: '團體報名資料及團體成績查詢', permission: true },
                },
                {
                    path: 'grades-inquiries/:searchID/:hash/:activity',
                    name: 'GroupGradesInquiries',
                    component: GradesInquiries,
                    props: true,
                    meta: { title: '成績查詢', subtitle: '考生個人成績查詢', permission: true },
                },
            ],
        },
        {
            path: '/history',
            component: Search,
            name: 'History',
            props: true,
            meta: { title: '個人成績查詢' },
            redirect: { name: 'HistorySearch' },
            children: [
                {
                    path: 'search',
                    name: 'HistorySearch',
                    component: HistorySearch,
                    meta: { title: '個人成績查詢' },
                },
                {
                    path: 'grades-inquiry-list/:searchID',
                    name: 'HistoryGradesInquiryList',
                    component: GradesInquiryList,
                    props: true,
                    meta: { title: '個人成績查詢', subtitle: '考生查詢', permission: true },
                },
                {
                    path: 'grades-inquiries/:searchID/:hash/:activity',
                    name: 'HistoryGradesInquiries',
                    component: GradesInquiries,
                    props: true,
                    meta: { title: '成績查詢', subtitle: '考生個人成績查詢', permission: true },
                },
            ],
        },
        {
            path: '/registration',
            component: Registration,
            name: 'Registration',
            meta: { title: '報名' },
            redirect: { name: 'RegistrationGroup' },
            children: [
                {
                    path: 'group',
                    name: 'RegistrationGroup',
                    component: RegistrationGroup,
                    meta: { title: '團體報名' },
                },
                {
                    path: 'personal',
                    name: 'RegistrationPersonal',
                    component: RegistrationPersonal,
                    meta: { title: '個人報名' },
                },
                {
                    path: 'personal/confirm',
                    name: 'RegistrationPersonalConfirm',
                    component: RegistrationPersonalConfirm,
                    meta: { title: '個人報名' },
                },
                {
                    path: 'personal/success/:serial',
                    name: 'RegistrationPersonalSuccess',
                    props: true,
                    component: RegistrationPersonalSuccess,
                    meta: { title: '個人報名' },
                },
                {
                    path: 'personal/pay-complete',
                    name: 'RegistrationPersonalPayComplete',
                    component: RegistrationPersonalPayComplete,
                    meta: { title: '個人報名' },
                },
            ],
        },
        {
            path: '/statistics',
            name: 'Statistics',
            meta: { title: '整體分數統計數據' },
            component: Statistics,
        },
        {
            path: '/history-exam',
            name: 'HistoryExam',
            meta: { title: '歷屆試題查詢' },
            component: HistoryExam,
        },
        {
            path: '/notexist',
            name: 'NotExist',
            meta: { title: '團體報名資料及團體成績查詢' },
            component: NotExist,
        },
        {
            path: '/contact',
            name: 'Contact',
            meta: { title: '聯絡我們' },
            component: Contact,
        },
        {
            path: '*',
            redirect: '/',
        },
    ],
});

export default router;

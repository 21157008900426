<template>
    <Layout class="home">
        <template #banner>
            <template v-if="banners.length > 0">
                <Swiper :data="banners">
                    <template slot-scope="{ slide }">
                        <img
                            :src="$static(slide)"
                            style="width: 100%"
                        >
                    </template>
                </Swiper>
            </template>
            <template v-else>
                <v-skeleton-loader type="image" />
            </template>
        </template>
        <div
            class="mt-5 mb-5"
            style="display: flex;justify-content: center;"
        >
            <v-btn
                rounded
                dark
                class="sign-up"
                @click="$router.push('/registration/personal')"
            >
                我要報名
            </v-btn>
        </div>
        <h1 class="home-title text-center">
            NEST全國小學學科能力競賽
        </h1>
        <h2
            class="home-sub"
            style="text-align: center;"
        >
            &lt;考試時程表&gt;
        </h2>
        <br>
        <v-expansion-panels>
            <v-expansion-panel
                v-for="(item, i) in list"
                :key="i"
            >
                <v-expansion-panel-header>
                    <b>{{ item.date | formatDate }}｜{{ item.title }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="var(--color-primary-light)">
                    <pre style="color: var(--color-primary); padding: 1em">
                        {{ item.content }}
                    </pre>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <div class="d-flex justify-center">
            <Pagination
                :total="total"
                :page-size="paginationOptions.itemsPerPage"
                :current-page="paginationOptions.page"
                background
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
    </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import Swiper from '@/components/Swiper.vue';
import { Pagination } from 'element-ui';

export default {
    components: {
        Swiper,
        Pagination,
    },
    data() {
        return {
            banners: [],
            expanded: [],
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            total: 0,
            list: [],
        };
    },
    created() {
        this.BannerList().then(({ data }) => {
            this.banners = data.raw?.map(({ file }) => file);
        });
        this.getScheduleList();
    },
    methods: {
        ...mapActions('mHome', ['BannerList', 'ScheduleList']),
        getScheduleList() {
            const { page, itemsPerPage } = this.paginationOptions;
            this.ScheduleList({
                offset: (page - 1) * itemsPerPage,
                limit: itemsPerPage,
            }).then((res) => {
                this.list = res.data.raw;
                this.total = res.data.total;
            });
        },
        handleSizeChange(size) {
            this.paginationOptions.itemsPerPage = size;
            this.getScheduleList();
        },
        handleCurrentChange(page) {
            this.paginationOptions.page = page;
            this.getScheduleList();
        },
    },
};
</script>

<style lang="scss" scoped>
    .home-title{
        font-size: 20px;
    }
    .home-sub{
        font-size: 16px;
    }
    .sign-up{
        padding: 0 62px !important;
        background:var(--color-primary-gradient);
    }
    @media (min-width:768px){
        .home-title{
            font-size: 32px;
            margin-bottom: 10px;
        }
        .home-sub{
            font-size: 26px;
            margin-bottom: 20px;
        }
        .sign-up{
            height: auto !important;
            font-size: 24px;
            font-weight: 700;
            padding: 30px 10px !important;
            border-radius: 0 10px 10px 0;
            writing-mode: vertical-rl;
            position: fixed;
            left: 0;
            top:50%;
            transform: translateY(-50%);
            background: linear-gradient(to left,#FF6B00,#F79010);
            z-index: 999;
            letter-spacing: 0.5rem;
        }
    }

</style>

<template>
    <Layout class="contact">
        <template #banner>
            <Banner
                title="聯絡我們"
                :src-desktop="require('@/assets/contact/banner.png')"
                :src-mobile="require('@/assets/contact/banner-mobile.jpg')"
            />
        </template>
        <div class="contact-information mb-3 ma-auto pa-5 pa-sm-10">
            <div class="contact-list d-flex">
                <v-row align="center">
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <div class="d-flex align-center">
                            <span class="material-icons md-24">phone</span>
                            <a
                                href="tel:04-2452-8152"
                                class="black--text"
                            >
                                04-2452-8152
                            </a>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <div class="d-flex align-center">
                            <span class="material-icons md-24">place</span>
                            <span>台中市西屯區上安路41號5樓</span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <div class="d-flex align-center">
                            <span class="material-icons md-24">email</span>
                            <span>
                                全國多元優質教育發展協會
                                <br>
                                <a
                                    href="mailto:nmqe2022@gmail.com"
                                    class="black--text"
                                >
                                    nmqe2022@gmail.com
                                </a>
                            </span>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <div class="d-flex align-center">
                            <span class="material-icons md-24">people</span>
                            <div
                                class="d-flex justify-center"
                                style="width: 80%;"
                            >
                                <a href="https://lin.ee/ekb05F0"><img
                                    src="https://scdn.line-apps.com/n/line_add_friends/btn/zh-Hant.png"
                                    alt="加入好友"
                                    height="36"
                                    border="0"
                                ></a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="contact-fb d-flex justify-center">
                <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100087110406691&tabs=timeline&width=500&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                    width="500"
                    height="125"
                    style="border:none;overflow:hidden"
                    scrolling="no"
                    frameborder="0"
                    allowfullscreen="false"
                    allow="autoplay;
                     clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
            </div>
        </div>
        <br>
        <p
            class="text-center"
            style="color: #363636"
        >
            您好，如果您有任何問題與建議都可與我們聯繫。我們將竭誠為您服務，滿足您的需求。
            <br>可以先至「<RouterLink to="/question">
                常見問題
            </RouterLink>」查看是否有相關疑問。
            <br>歡迎留下您的問題，或請來電客服專線04-2452-8152以得到及時的協助。
        </p>
        <v-form
            ref="form"
            v-model="formValidate"
            class="ma-auto pa-5 pa-sm-10"
        >
            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-select
                        v-model="formData.type"
                        :items="[
                            'NEST相關問題',
                            '考生資料問題',
                            '修改已報名資料',
                            '其他',
                        ]"
                        :rules="rules.type"
                        label="選擇問題"
                        outlined
                    />
                    <v-text-field
                        v-model="formData.name"
                        :rules="rules.name"
                        label="姓名"
                        outlined
                    />
                    <v-text-field
                        v-model="formData.email"
                        :rules="rules.email"
                        label="Email"
                        outlined
                    />
                    <v-text-field
                        v-model="formData.phone"
                        :rules="rules.phone"
                        label="電話"
                        outlined
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-textarea
                        v-model="formData.message"
                        :rules="rules.message"
                        label="留言"
                        placeholder="在此輸入您的留言..."
                        outlined
                        rows="11"
                    />
                </v-col>
            </v-row>
            <br>
            <v-btn
                rounded
                dark
                class="ma-auto d-block px-16"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="submit"
            >
                送出
            </v-btn>
        </v-form>
    </Layout>
</template>

<script>
import API from '@/api';
import * as utils from '../store/utils';

export default {
    data() {
        return {
            formValidate: false,
            formData: {
                type: '',
                name: '',
                email: '',
                phone: '',
                message: '',
            },
        };
    },
    computed: {
        rules() {
            const required = (label) => (v) => !!v || (label ? `${label} 必填` : 'Required');
            return {
                type: [required('問題類別')],
                name: [required('姓名')],
                email: [required('Email'), (v) => /.+@.+/.test(v) || 'Email 格式'],
                phone: [required('電話')],
                message: [required('留言')],
            };
        },
    },
    methods: {
        async submit() {
            this.$refs.form.validate();
            if (this.formValidate) {
                try {
                    await API.ContactUtil.Mailer({
                        question: this.formData.type,
                        name: this.formData.name,
                        email: this.formData.email,
                        phone: this.formData.phone,
                        content: this.formData.message,
                    });
                    utils.handleAPISuccess('寄送成功', '感謝您的反應，我們會盡快處理回覆！');
                    this.formData = {
                        type: '',
                        name: '',
                        email: '',
                        phone: '',
                        message: '',
                    };
                } catch (e) {
                    utils.handleAPIError(e);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.material-icons {
    color: var(--color-primary);
    margin-right: 10px;
}
.contact-information{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .contact-list{
        width: 80%;
    }
    .contact-fb{
        margin-top: 30px;
        width: 100%;
    }
}
@media screen and (min-width: 600px) {
    .contact-information{
        flex-wrap: nowrap;
        justify-content: space-between;
        .contact-list{
            width: 40%;
        }
        .contact-fb{
            margin-top: 0;
            width: 48%;
        }
    }
}
@media screen and (min-width: 960px) {
    .contact-information{
        justify-content: space-evenly;
        .contact-list{
            width: 60%;
        }
        .contact-fb{
            width: 35%;
        }
    }
}
</style>

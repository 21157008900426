<template>
    <Layout class="statistics">
        <template #banner>
            <Banner
                title="整體分數統計數據"
                :src-desktop="require('@/assets/statistics/banner.png')"
                :src-mobile="require('@/assets/statistics/banner-mobile.jpg')"
            />
        </template>
        <v-card class="rounded-lg">
            <h1>整體分數統計數據</h1>
            <template v-if="activityData.result">
                <template v-for="(item, i) in activityData.result">
                    <div
                        :key="i"
                        class="d-flex flex-column align-center mx-auto px-5 py-10"
                    >
                        <h2>
                            {{ item.grade }}
                        </h2>
                        <br>
                        <img
                            :src="$static(item.image)"
                            width="100%"
                        >
                    </div>
                </template>
            </template>
        </v-card>
    </Layout>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('mActivity', ['activityData']),
    },
};
</script>

<style lang="scss" scoped>
h1 {
    color: #fff;
    text-align: center;
    padding: 0.3em 0.5em;
    letter-spacing: 5px;
    background-image: -webkit-repeating-linear-gradient(
            -60deg,
            rgba(255, 255, 255, 0.25) 0,
            rgba(255, 255, 255, 0.25) 2px,
            var(--color-primary) 0,
            var(--color-primary) 15px
        ),
        var(--color-primary-gradient);
    background-image: repeating-linear-gradient(
            -60deg,
            rgba(255, 255, 255, 0.25) 0,
            rgba(255, 255, 255, 0.25) 2px,
            var(--color-primary) 0,
            var(--color-primary) 15px
        ),
        var(--color-primary-gradient);
}

h2 {
    display: list-item;
    list-style-type: disc;

    &::marker {
        color: var(--color-primary);
    }
}
</style>

import API from '@/api';
import * as utils from '../utils';

export default {
    namespaced: true,
    state: {
        settings: {
            說明事項: '',
            應試規則: '',
            退費辦法: '',
            當前活動: '',
        },
    },
    actions: {
        PublicInfo({ state }) {
            return API.Setting.PublicInfo()
                .then((res) => {
                    state.settings = res.data;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
    },
};

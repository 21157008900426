<template>
    <v-card>
        <div style="background: var(--color-secondary); color: #fff">
            <v-card-title class="text-h5 text-center justify-center font-weight-bold">
                考生個人成績查詢
            </v-card-title>
        </div>
        <v-form
            ref="form"
            v-model="formValidate"
            class="ma-auto pa-5 pa-sm-10"
            style="max-width: 600px"
        >
            <v-row>
                <v-col
                    cols="5"
                    sm="4"
                >
                    考生身分證字號
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="formData.identity"
                        outlined
                        dense
                        :rules="rules.identity"
                    />
                </v-col>
            </v-row>
            <v-row class="mb-0">
                <v-col
                    cols="5"
                    sm="4"
                >
                    密碼
                    <v-subheader
                        class="pa-0"
                        style="height: auto"
                    >
                        *密碼預設為考生生日
                    </v-subheader>
                </v-col>
                <v-col>
                    <v-row class="mb-0">
                        <v-col
                            cols="12"
                            sm="6"
                            class="pb-0"
                        >
                            <v-select
                                v-model="formData.month"
                                :items="generateNumberArray(1, 12)"
                                :rules="rules.month"
                                outlined
                                dense
                                suffix="月"
                            >
                                <template #item="{ item }">
                                    {{ item }} 月
                                </template>
                            </v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            class="pb-0"
                        >
                            <v-select
                                v-model="formData.day"
                                :items="generateNumberArray(1, 31)"
                                :rules="rules.day"
                                outlined
                                dense
                                suffix="日"
                            >
                                <template #item="{ item }">
                                    {{ item }} 日
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-btn
                rounded
                dark
                class="ma-auto d-block px-16"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="submit"
            >
                查詢
            </v-btn>
        </v-form>
    </v-card>
</template>

<script>
import jwt from 'jsonwebtoken';
import { isNationalIdentificationNumberValid, isNewResidentCertificateNumberValid } from 'taiwan-id-validator';
import { generateNumberArray } from '@/utils/methods';
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            formValidate: false,
            formData: {
                identity: '',
                month: '',
                day: '',
            },
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        rules() {
            const required = (label) => (v) => !!v || (label ? `${label} 必填` : 'Required');
            return {
                identity: [required('身份證'), (v) => isNationalIdentificationNumberValid(v) || isNewResidentCertificateNumberValid(v) || '身份證 格式有誤'],
                month: [required('月份')],
                day: [required('日期')],
            };
        },
    },
    methods: {
        generateNumberArray,
        ...mapActions([
            'mApply/historyQuery',
        ]),
        submit() {
            this.$refs.form.validate();
            if (this.formValidate) {
                const params = this.formData;
                this['mApply/historyQuery'](params)
                    .then(() => {
                        const searchID = this.formData.identity;
                        const encoded = jwt.sign({
                            ...params,
                            searchID,
                        }, process.env.VUE_APP_TOKEN_KEY, {
                            expiresIn: process.env.VUE_APP_TOKEN_EXPIRE_IN,
                        });
                        localStorage.setItem(process.env.VUE_APP_TOKEN_KEY, encoded);
                        this.$router.push(`/history/grades-inquiry-list/${searchID}`);
                    });
            }
        },
    },
};
</script>

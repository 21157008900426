<template>
    <v-card class="pa-5 pa-sm-10">
        <h2 class="text-center">
            團體報名方式
        </h2>
        <br>
        <p class="mb-0">
            下載團體報名表格，依表格中的規定填寫完正確的報名資料後，寄送至全國多元優質教育發展協會信箱nmqe2022@gmail.com。
        </p>
        <v-divider class="my-4" />
        <p>
            <b class="text-h6">信件內容請包含</b>
            <br>信件主旨：全國小學學科能力競賽團體報名【團體名稱】
            <br>信件內容：
            <ol>
                <li>團體名稱：</li>
                <li>聯絡人：</li>
                <li>聯絡人電話：</li>
                <li>聯絡人通訊地址：</li>
                <li>報名人數：</li>
                <li>匯款資訊：匯款完成後，請告知帳號後五碼</li>
                <li>聯絡人自設密碼（用於查詢准考證號碼及成績，請設定6~20位英數字密碼）：</li>
            </ol>
        </p>
        <p>
            <b class="text-h6">「團體報名信件寄出後，NEST辦公室會進行資料確認並提供匯款資訊。」</b>
        </p>
        <v-divider class="my-5" />
        <div class="d-flex justify-center">
            <v-btn
                rounded
                dark
                class="px-16"
                style="background: var(--color-primary-gradient)"
                href="https://drive.google.com/drive/folders/1HTWZjUXYPaFiT-qGQ3jD2TSnbIE0o4nk?usp=sharing"
                target="_blank"
            >
                下載團體報名表格
            </v-btn>
        </div>
    </v-card>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

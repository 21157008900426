<template>
    <div class="about">
        <Banner
            title="常見問題"
            :src-desktop="require('@/assets/regulations/banner.png')"
            :src-mobile="require('@/assets/regulations/banner-mobile.png')"
        >
            <span style="color: #000">{{ activityData.title }}</span>
        </Banner>
        <v-row class="my-0">
            <v-col
                sm="10"
                lg="8"
                class="mx-auto"
            >
                <v-btn-toggle
                    :value="type"
                    rounded
                    mandatory
                    dense
                    active-class="active-tab"
                    class="d-flex my-5 my-sm-10"
                    @change="handleChange"
                >
                    <v-btn
                        value="activity"
                        class="tab"
                    >
                        活動辦法
                    </v-btn>
                    <v-btn
                        value="scope"
                        class="tab"
                    >
                        考試範圍
                    </v-btn>
                    <v-btn
                        value="refund"
                        class="tab"
                    >
                        退費辦法
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <router-view />
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    components: {},
    data() {
        return {
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        type() {
            return this.$route.path.replace('/regulations/', '');
        },
    },
    created() { },
    mounted() { },
    methods: {
        handleChange(val) {
            if (!this.$route.path.includes(val)) {
                this.$router.push(`/regulations/${val}`);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tab{
    font-size: 16px;
    padding: 20px 0 !important;
}
.active-tab {
    color: #fff !important;
    background: var(--color-highlight) !important;
    border-width: thin !important;
}

.v-btn-toggle {
    min-width: 80%;
    margin: auto;

    > * {
        flex: 1;
    }
}
@media(min-width:760px){
    .tab{
        font-size: 20px;
        padding: 25px 0 !important;
    }
}
</style>

<template>
    <v-card class="pa-5 pa-sm-10">
        <h2
            v-if="closedRegistration"
            class="text-center"
        >
            非報名時間
        </h2>
        <template v-else>
            <h2 class="text-center form-h2">
                NEST全科小學學科能力競賽報名表
            </h2>
            <v-form
                ref="form"
                v-model="formValidate"
                class="ma-auto"
                style="max-width: 700px"
            >
                <p class="form-title">
                    ❮<span style="color: red">Notice!</span>
                    報考年級為考試日當下學生年級或者越級報考 ❯
                </p>
                <v-select
                    v-model="competitionId"
                    :items="competitionList"
                    :rules="rules.competitionId"
                    label="報考項目"
                    outlined
                />
                <v-select
                    v-model="formData.place"
                    :items="placeList"
                    :rules="rules.place"
                    label="考場選擇"
                    outlined
                />
                <v-text-field
                    v-model="formData.name"
                    :rules="rules.name"
                    label="姓名"
                    outlined
                />
                <v-radio-group
                    v-model="formData.gender"
                    :rules="rules.gender"
                    class="mt-0 mb-2"
                    row
                >
                    <v-radio
                        label="男"
                        value="男"
                    />
                    <v-radio
                        label="女"
                        value="女"
                    />
                </v-radio-group>
                <v-text-field
                    v-model="formData.identity"
                    :rules="rules.identity"
                    label="身份證字號"
                    outlined
                />
                <v-row class="mb-0">
                    <v-col
                        cols="12"
                        sm="4"
                        class="pb-0"
                    >
                        <v-select
                            v-model="formData.birthYear"
                            :items="generateNumberArray(83, getNowChineseYear())"
                            :rules="rules.birthYear"
                            outlined
                            label="出生"
                            suffix="年"
                        >
                            <template #item="{ item }">
                                {{ item }} 年
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        class="pb-0"
                    >
                        <v-select
                            v-model="formData.birthMonth"
                            :items="generateNumberArray(1, 12)"
                            :rules="rules.birthMonth"
                            outlined
                            suffix="月"
                        >
                            <template #item="{ item }">
                                {{ item }} 月
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        class="pb-0"
                    >
                        <v-select
                            v-model="formData.birthDay"
                            :items="generateNumberArray(1, 31)"
                            :rules="rules.birthDay"
                            outlined
                            suffix="日"
                        >
                            <template #item="{ item }">
                                {{ item }} 日
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
                <v-row class="mb-0">
                    <v-col
                        cols="12"
                        sm="6"
                        class="pb-0"
                    >
                        <v-select
                            v-model="formData.city"
                            :items="formOption.city"
                            :rules="rules.city"
                            outlined
                            label="就讀縣市"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="pb-0"
                    >
                        <v-text-field
                            v-model="formData.school"
                            :rules="rules.school"
                            label="就讀國小"
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-row class="mb-0">
                    <v-col
                        cols="12"
                        sm="6"
                        class="pb-0"
                    >
                        <v-select
                            v-model="formData.grade"
                            :items="['一', '二', '三', '四', '五', '六']"
                            :rules="rules.grade"
                            outlined
                            label="年級"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        class="pb-0"
                    >
                        <v-text-field
                            v-model="formData.class"
                            :rules="rules.class"
                            label="班別"
                            outlined
                        />
                    </v-col>
                </v-row>
                <v-text-field
                    v-model="formData.parentName"
                    :rules="rules.parentName"
                    label="家長姓名"
                    outlined
                />
                <v-text-field
                    v-model="formData.cellphone"
                    :rules="rules.cellphone"
                    label="手機"
                    outlined
                />
                <v-text-field
                    v-model="formData.telephone"
                    :rules="rules.telephone"
                    label="家用電話"
                    outlined
                />
                <v-text-field
                    v-model="formData.postalCode"
                    :rules="rules.postalCode"
                    label="郵遞區號(3碼)"
                    class="postalCode-field"
                    outlined
                    maxlength="3"
                    @input="handlePostalCode"
                />
                <v-text-field
                    v-model="formData.county"
                    label="縣市與地區(自動生成)"
                    class="place-field"
                    outlined
                    disabled
                />
                <v-text-field
                    v-model="formData.address"
                    :rules="rules.address"
                    label="通訊地址"
                    outlined
                    placeholder="例如: 信義路五段7號"
                />
                <v-textarea
                    v-model="formData.remark"
                    :rules="rules.remark"
                    label="備註欄"
                    outlined
                />
            </v-form>
            <br>
            <v-btn
                rounded
                dark
                class="ma-auto d-block px-16"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="validate"
            >
                下一步
            </v-btn>
        </template>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import {
    isNationalIdentificationNumberValid,
    isNewResidentCertificateNumberValid,
} from 'taiwan-id-validator';
import formOption from '@/assets/form-options.json';
import { generateNumberArray, getNowChineseYear } from '@/utils/methods';
import postalCodeLists from '@/utils/postal-code-search';

export default {
    data() {
        return {
            formOption,
            formValidate: false,
            competitionId: '',
            formData: {
                county: '',
                place: '',
                name: '',
                gender: '',
                identity: '',
                birthYear: '',
                birthMonth: '',
                birthDay: '',
                city: '',
                school: '',
                grade: '',
                class: '',
                parentName: '',
                cellphone: '',
                telephone: '',
                postalCode: '',
                address: '',
                remark: '',
            },
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        ...mapState({
            competitionList: (state) => state.mCompetition.competitionList?.map((item) => ({
                ...item,
                text: item.name,
                value: item.id,
            })) || [],
        }),
        placeList() {
            return (
                this.competitionList
                    .find((item) => this.competitionId === item.id)
                    ?.locations?.map((item) => ({
                        ...item,
                        // text: `${item.name}（${item.address}`,
                        // eslint-disable-next-line prefer-template
                        text: item.name + '（' + item.address + '）',
                        value: item.name,
                    })) || []
            );
        },
        rules() {
            const required = (label) => (v) => !!v || (label ? `${label} 必填` : 'Required');
            return {
                competitionId: [required('報考項目')],
                place: [required('考場')],
                name: [required('姓名')],
                gender: [required('性別')],
                identity: [
                    required('身份證'),
                    (v) => isNationalIdentificationNumberValid(v)
                        || isNewResidentCertificateNumberValid(v)
                        || '身份證 格式有誤',
                ],
                birthYear: [required('出生年')],
                birthMonth: [required('出生月')],
                birthDay: [required('出生日')],
                city: [required('就讀縣市')],
                school: [required('就讀國小')],
                grade: [required('年級')],
                class: [required('班別')],
                parentName: [required('家長姓名')],
                cellphone: [required('手機')],
                telephone: [],
                postalCode: [
                    required('郵遞區號'),
                    (v) => (postalCodeLists[v] ? true : '查無郵遞區號'),
                ],
                address: [required('通訊地址')],
                remark: [],
            };
        },
        closedRegistration() {
            const start = moment(this.activityData?.applyStart || Date.now()).startOf('day');
            const end = moment(this.activityData?.applyEnd || Date.now()).endOf('day');
            return (
                moment.duration(moment().subtract(start)).asMilliseconds() < 0
                || moment.duration(moment().subtract(end)).asMilliseconds() > 0
            );
        },
    },
    mounted() {
        jwt.verify(
            localStorage.getItem('formData'),
            process.env.VUE_APP_TOKEN_KEY,
            (err, decoded) => {
                // token 過期 or 解密錯誤：移除 token 並要求重新查詢
                if (err) localStorage.removeItem('formData');
                else {
                    this.competitionId = decoded.competitionId;
                    this.formData = decoded.data;
                }
            },
        );
    },
    methods: {
        ...mapActions('mApply', ['personalApply']),
        generateNumberArray,
        getNowChineseYear,
        validate() {
            this.$refs.form.validate();
            if (this.formValidate) {
                localStorage.setItem(
                    'formData',
                    jwt.sign(
                        {
                            data: this.formData,
                            competitionId: this.competitionId,
                        },
                        process.env.VUE_APP_TOKEN_KEY,
                    ),
                );
                this.$router.push('/registration/personal/confirm');
            }
        },
        handlePostalCode() {
            this.formData.county = postalCodeLists[this.formData.postalCode];
        },
    },
};
</script>
<style>
.form-h2 {
    font-size: 24px;
    margin-bottom: 10px;
}
.form-title {
    margin-bottom: 12px !important;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}
.postalCode-field,
.place-field {
    width: 100%;
    display: inline-block;
}
@media screen and (min-width: 600px) {
    .postalCode-field {
        width: 28%;
    }
    .place-field {
        width: 70%;
        margin-left: 2% !important;
    }
}
@media screen and (min-width: 960px) {
    .form-h2 {
        font-size: 28px;
    }
    .form-title {
        font-size: 16px;
        margin-bottom: 20px !important;
    }
}
</style>

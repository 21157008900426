<template>
    <div class="report mb-sm-10">
        <div style="background: var(--color-primary-light)">
            <Banner
                title="成績報告書簡介"
                :src-desktop="require('@/assets/report/banner.png')"
                :src-mobile="require('@/assets/report/banner-mobile.png')"
            />
            <section1 />
        </div>
        <v-divider style="border-color: var(--color-primary)" />
        <section2 />
        <v-divider style="border-color: var(--color-primary)" />
        <section3 />
        <v-divider style="border-color: var(--color-primary)" />
        <section4 />
        <v-divider style="border-color: var(--color-primary)" />
        <section5 />
    </div>
</template>

<script>
import Section1 from '@/components/report/section1.vue';
import Section2 from '@/components/report/section2.vue';
import Section3 from '@/components/report/section3.vue';
import Section4 from '@/components/report/section4.vue';
import Section5 from '@/components/report/section5.vue';

export default {
    components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
    },
    data() {
        return {
        };
    },
    computed: {},
    created() { },
    mounted() { },
    methods: {},
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-width: 2px 0;
    border-style: solid;
    border-color: #e2e2e2;

    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after {
            content: "";
            display: block;
            height: 5px;
            width: 100%;
            border-radius: 100px;
            background: var(--color-highlight);
        }
    }
}
</style>

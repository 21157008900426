<template>
    <div>
        <!-- 參加資格 -->
        <div class="d-flex flex-column align-center pa-5 py-sm-5">
            <span class="material-icons md-24 mb-2">person</span>
            <h3 class="mb-5">
                參賽資格
            </h3>
            <pre>{{ activityData.qualification }}</pre>
        </div>
        <!-- 報名費用 -->
        <div class="d-flex flex-column align-center pa-5 py-sm-5">
            <span class="material-icons md-24 mb-2">paid</span>
            <h3 class="mb-5">
                報名費用
            </h3>
            <pre>{{ activityData.fee }}</pre>
        </div>
        <div style="background: var(--color-primary-light)">
            <img
                src="@/assets/regulations-activity/BG-2.png"
                width="100%"
            >
            <div class="content pa-5 py-sm-10">
                <!-- 報名贈品 -->
                <div
                    v-if="activityData.giveaway.thumb!==''"
                    class="d-flex flex-column align-center mb-5 mb-sm-10"
                >
                    <v-icon class="material-icons md-24 mb-2">
                        mdi-gift
                    </v-icon>
                    <h3>報名贈品</h3>
                </div>
                <v-card
                    v-if="activityData.giveaway.thumb!==''"
                    class="pa-5 py-sm-10"
                >
                    <div class="d-flex flex-column flex-sm-row align-center">
                        <img
                            :src="$static(activityData.giveaway.thumb)"
                            class="rwd-sm-6"
                        >
                        <div class="d-flex flex-column px-sm-10">
                            <b>
                                {{ activityData.giveaway.title }}</b>
                            <pre>
                            {{ activityData.giveaway.description }}</pre>
                        </div>
                    </div>
                </v-card>
                <br>
                <br>
                <!-- 獎項說明 -->
                <div class="d-flex flex-column align-center mb-5 mb-sm-10">
                    <span class="material-icons md-24 mb-2">emoji_events</span>
                    <h3>獎項說明</h3>
                </div>
                <v-card
                    v-if="activityData.prize.thumb!==''"
                    class="pa-5 py-sm-10"
                >
                    <div class="d-flex flex-column flex-sm-row align-center">
                        <img
                            :src="$static(activityData.prize.thumb)"
                            class="rwd-sm-6"
                        >
                        <div class="d-flex flex-column px-sm-10">
                            <b>{{ activityData.prize.title }}</b>
                            <pre>{{ activityData.prize.description }}</pre>
                        </div>
                    </div>
                </v-card>
            </div>
            <img
                src="@/assets/regulations-activity/BG-3.png"
                width="100%"
            >
        </div>
        <!-- 競賽日期時間及地點 -->
        <div
            class="d-flex flex-column align-center pa-5 py-sm-5"
        >
            <span class="material-icons md-24 mb-2">push_pin</span>
            <h3 class="mb-5">
                競賽日期時間及地點
            </h3>
            <v-expansion-panels
                class="px-5 py-10"
                style="max-width: 1000px;"
            >
                <v-expansion-panel
                    v-for="item in competitionList"
                    :key="item.id"
                >
                    <v-expansion-panel-header>
                        <b class="header">
                            <div class="q-title">
                                {{ item.name }}
                            </div>

                        </b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                        color="var(--color-primary-light)"
                        style="padding: 20px 0;"
                    >
                        <table>
                            <tr>
                                <th>競賽名稱&nbsp;:</th>
                                <td>{{ item.name }}</td>
                            </tr>
                            <tr>
                                <th>日&emsp;期&emsp;&nbsp;:</th>
                                <td>{{ item.date | formatDate }}</td>
                            </tr>
                            <tr>
                                <th>時&emsp;間&emsp;&nbsp;:</th>
                                <td>{{ item.time }}</td>
                            </tr>
                            <tr>
                                <th>費&emsp;用&emsp;&nbsp;:</th>
                                <td>{{ item.fee }}元</td>
                            </tr>
                            <tr>
                                <th>地&emsp;點&emsp;&nbsp;:</th>
                                <td>
                                    <ol>
                                        <li
                                            v-for="location in item.locations"
                                            :key="location.name"
                                        >
                                            <div class="d-flex flex-column mb-2">
                                                <span class="mb-1">{{ location.name }}</span>
                                                <span class="mb-2">（{{ location.address }}）</span>
                                                <div
                                                    class="d-flex align-center"
                                                    style="flex-wrap: wrap;"
                                                >
                                                    <v-btn
                                                        v-if="location.planimetricMap"
                                                        :href="$static(location.planimetricMap)"
                                                        :style="{
                                                            background:
                                                                'var(--color-primary-gradient)',
                                                        }"
                                                        rounded
                                                        x-small
                                                        dark
                                                        class="mr-2 mb-2"
                                                        target="_blank"
                                                    >
                                                        下載試場平面圖
                                                    </v-btn>
                                                    <v-btn
                                                        v-if="location.googleMap"
                                                        :href="location.googleMap"
                                                        :style="{
                                                            background:
                                                                'var(--color-secondary-gradient)',
                                                        }"
                                                        rounded
                                                        x-small
                                                        dark
                                                        class="mb-2"
                                                        target="_blank"
                                                    >
                                                        Google Maps
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </li>
                                    </ol>
                                </td>
                            </tr>
                        </table>
                        <div class="d-flex align-center ml-5 my-5">
                            <span class="material-icons md-18">article</span>
                            <div
                                class="ml-2"
                                style="font-weight: 700;"
                            >
                                應試節次圖:
                            </div>
                        </div>
                        <div
                            class="d-flex align-center mb-5 ml-5"
                        >
                            <div
                                class="d-flex align-center schedule"
                            >
                                <img
                                    :src="$static(item.examTimetable)"
                                    class="rwd-12"
                                >
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('mActivity', ['activityData']),
        ...mapState('mCompetition', ['competitionList']),
    },
};
</script>

<style lang="scss" scoped>
.q-title{
    font-size: 1.17em;
    line-height: 1.5;
}
.material-icons {
    background: var(--color-primary);
    color: #fff;
    border-radius: 100px;
    padding: 5px;
    vertical-align: middle;
    user-select: none;
}
.content {
    max-width: 1000px;
    margin: auto;
}
table {
    width: 100%;
    max-width: 600px;
    border-collapse: separate;
    border-spacing: 10px 5px;

    th {
        white-space: nowrap;
        width: 6em;
    }

    th,
    td {
        vertical-align: top;
    }
}
.schedule{
    width: 100%;
}
@media screen and (min-width: 600px) {
    .schedule{
        width: 50%;
        max-width: 460px;
    }
}
</style>

<template>
    <Layout>
        <template #banner>
            <Banner
                :title="title"
                :src-desktop="require('@/assets/search/banner.png')"
                :src-mobile="require('@/assets/search/banner-mobile.png')"
            />
        </template>
        <router-view />
    </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import jwt from 'jsonwebtoken';

export default {
    computed: {
        title() {
            return this.$route.meta.title;
        },
    },
    async created() {
        const decodeData = jwt.decode(localStorage.getItem(process.env.VUE_APP_TOKEN_KEY));
        try {
            if (this.$route.path.includes('personal')) {
                await this['mApply/personalQuery'](decodeData);
            } else if (this.$route.path.includes('group')) {
                await this['mApply/groupQuery'](decodeData);
            } else if (this.$route.path.includes('history')) {
                await this['mApply/historyQuery'](decodeData);
            } else if (this.$route.name !== 'PersonalSearch' && this.$route.path.includes('personal')) {
                this.$router.replace({ name: 'PersonalSearch' });
            } else if (this.$route.name !== 'GroupSearch' && this.$route.path.includes('group')) {
                this.$router.replace({ name: 'GroupSearch' });
            } else if (this.$route.name !== 'HistorySearch' && this.$route.path.includes('history')) {
                this.$router.replace({ name: 'HistorySearch' });
            }
        } catch (error) {
            localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
        }
    },
    methods: {
        ...mapActions([
            'mApply/personalQuery',
            'mApply/groupQuery',
            'mApply/scoreQuery',
            'mApply/historyQuery',
        ]),
    },
};
</script>

import Vue from 'vue';
import API from '@/api';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Layout from './components/Layout.vue';
import Banner from './components/Banner.vue';
import { formatDate } from './utils/methods';
import './utils/permission';

import 'element-ui/lib/theme-chalk/index.css';

require('./scss/_element.scss');
require('./scss/reset.scss');
require('./scss/color.scss');
require('./scss/rwd.scss');
require('./scss/icon-font.scss');

Vue.config.productionTip = false;

Vue.component('Layout', Layout);
Vue.component('Banner', Banner);
Vue.prototype.$static = API.Static;

Vue.filter('formatDate', formatDate);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');

<template>
    <v-card>
        <div style="background: var(--color-secondary); color: #fff">
            <v-card-title
                class="text-h5 text-center justify-center font-weight-bold"
            >
                團體報名資料及團體成績查詢
            </v-card-title>
        </div>
        <v-form
            ref="form"
            v-model="formValidate"
            class="ma-auto pa-5 pa-sm-10"
            style="max-width: 500px"
        >
            <v-text-field
                v-model="formData.cellphone"
                label="報名者手機"
                outlined
                class="mb-3 mb-sm-5"
                hide-details="auto"
                :rules="rules.cellphone"
            />
            <v-text-field
                v-model="formData.password"
                label="報名者自設密碼"
                outlined
                class="mb-3 mb-sm-5"
                hide-details="auto"
                :rules="rules.password"
            />
            <v-btn
                rounded
                dark
                class="ma-auto d-block px-16"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="submit"
            >
                查詢
            </v-btn>
        </v-form>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import jwt from 'jsonwebtoken';
import * as utils from '../store/utils';

export default {
    data() {
        return {
            formValidate: false,
            formData: {
                cellphone: '',
                password: '',
            },
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        rules() {
            const required = (label) => (v) => !!v || (label ? `${label} 必填` : 'Required');
            return {
                cellphone: [required('手機')],
                password: [required('密碼')],
            };
        },
    },
    methods: {
        ...mapActions([
            'mApply/groupQuery',
        ]),
        submit() {
            this.$refs.form.validate();
            if (this.formValidate) {
                const params = {
                    ...this.formData,
                    activity: this.activityData.id,
                };
                this['mApply/groupQuery'](params)
                    .then((res) => {
                        const searchID = this.formData.cellphone;
                        const encoded = jwt.sign({
                            ...params,
                            searchID,
                        }, process.env.VUE_APP_TOKEN_KEY, {
                            expiresIn: process.env.VUE_APP_TOKEN_EXPIRE_IN,
                        });
                        localStorage.setItem(process.env.VUE_APP_TOKEN_KEY, encoded);
                        if (res !== 'error') {
                            this.$router.push(`/group/list/${searchID}`);
                        } else {
                            utils.handleAPIError('手機或密碼錯誤');
                        }
                    });
            }
        },
    },
};
</script>

<template>
    <Layout class="statistics">
        <template #banner>
            <Banner
                title="歷屆試題查詢"
                :src-desktop="require('@/assets/search/banner.png')"
                :src-mobile="require('@/assets/search/banner-mobile.png')"
            />
        </template>
        <div
            class="pa-5"
            style="max-width: 1000px; margin: auto"
        >
            <template v-for="(subjects, activity) in list">
                <div
                    :key="activity"
                    class="d-flex flex-column align-center"
                    style="position: relative"
                >
                    <h3
                        class="list-type-title mt-10"
                        style="font-size: 18px"
                    >
                        {{ activity }}
                    </h3>
                    <v-expansion-panels class="mt-2">
                        <v-expansion-panel
                            v-for="(items, grade) in subjects"
                            :key="grade"
                        >
                            <v-expansion-panel-header>
                                <h3 style="font-size: 18px">
                                    {{ grade }}
                                </h3>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <li
                                    v-for="(item, i) in items"
                                    :key="i"
                                    class="item"
                                >
                                    <h3 class="subject">
                                        {{ item.subject }}
                                    </h3>
                                    <v-btn
                                        rounded
                                        dark
                                        class="ma-auto d-block px-6"
                                        :style="{
                                            background: 'var(--color-primary-gradient)',
                                            height: 'auto',
                                            padding: '8px 0',
                                        }"
                                        :href="$static(item.pdfLink)"
                                    >
                                        下載
                                        <span
                                            class="material-icons-outlined md-18 ml-1"
                                        >file_download</span>
                                    </v-btn>
                                </li>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </template>
        </div>
        <a id="downloadBtn" />
    </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import chineseToNumber from 'chinese-to-number';

export default {
    data() {
        return {
            list: [],
        };
    },
    computed: {},
    created() {
        this.handleList();
    },
    methods: {
        ...mapActions('mExam', ['List']),
        handleList() {
            this.List().then((res) => {
                const data = res.data.raw;
                const result = {};
                data.forEach((item) => {
                    const { activity, grade } = item;
                    if (!result[activity]) {
                        result[activity] = {};
                    }
                    if (!result[activity][grade]) {
                        result[activity][grade] = [];
                    }
                    result[activity][grade].push(item);
                });
                Object.entries(result).forEach(([grades]) => {
                    Object.entries(grades).forEach(([items]) => {
                        if (Array.isArray(items)) {
                            items.sort((a, b) => a.weight - b.weight);
                        }
                    });
                });

                const sortedKeys = Object.keys(result).sort((a, b) => {
                    const matchA = a.match(/第([\u4e00-\u9fa5]+)屆/);
                    const matchB = b.match(/第([\u4e00-\u9fa5]+)屆/);

                    const numberA = matchA ? chineseToNumber(matchA[1]) : null;
                    const numberB = matchB ? chineseToNumber(matchB[1]) : null;

                    if (numberA !== null && numberB !== null) {
                        return numberB - numberA;
                    } if (numberA !== null) {
                        return -1;
                    } if (numberB !== null) {
                        return 1;
                    }
                    return 0;
                });

                const sortedData = {};
                sortedKeys.forEach((key) => {
                    const sortedGrades = Object.keys(result[key]).sort((a, b) => {
                        const gradeA = a.replace('年級', '');
                        const gradeB = b.replace('年級', '');
                        const numberA = chineseToNumber(gradeA);
                        const numberB = chineseToNumber(gradeB);
                        return numberA - numberB;
                    });

                    const sortedGradesData = {};
                    sortedGrades.forEach((grade) => {
                        sortedGradesData[grade] = result[key][grade];
                    });
                    sortedData[key] = sortedGradesData;
                });

                this.list = sortedData;
            });
        },
    },
};
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
    padding: 0;
}
.statistics {
    .item {
        display: flex;
        align-items: center;
        padding: 16px 24px;
        background-color: #ecf8f6;
        border: 1px solid #e2e2e2;
        .subject {
            font-size: 18px;
            font-weight: 700;
            flex: 1;
        }
    }
    .title {
        height: 100px;
        position: relative;
        color: #fff;
        background-image: url("../assets/title.png");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        @media screen and (min-width: 600px) {
            height: 200px;
        }
    }

    .list-type-title {
        display: list-item;
        list-style-type: disc;
        margin-bottom: 10px;
        &::marker {
            color: var(--color-primary);
        }
        @media screen and (min-width: 600px) {
            margin-bottom: 25px;
        }
    }
    .downloadScope {
        justify-content: center;
        position: relative;
        @media screen and (min-width: 600px) {
            position: absolute;
            top: 55px;
            justify-content: flex-end;
        }
    }
}
</style>

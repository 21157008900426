<template>
    <v-card class="pa-5 pa-sm-10">
        <h2 class="text-center">
            報名成功 前往繳費
        </h2>
        <br>
        <br>
        <v-simple-table>
            <tbody>
                <tr>
                    <th>報考項目</th>
                    <td>{{ competitionData.name }}</td>
                </tr>
                <tr>
                    <th>贈品</th>
                    <td>
                        <template v-if="activityData.giveaway">
                            {{ activityData.giveaway.title }}
                        </template>
                    </td>
                </tr>
                <tr>
                    <th>報考費用</th>
                    <td>{{ competitionData.fee }} 元&emsp;*含贈品(免費)</td>
                </tr>
                <tr>
                    <th
                        colspan="2"
                        class="text-center"
                        style="color: var(--color-primary)"
                    >
                        *若暫不繳費，後續可至個人報名資料及繳費查詢-繳費查詢繼續繳費。
                    </th>
                </tr>
            </tbody>
        </v-simple-table>
        <br>
        <div class="d-flex justify-center">
            <v-btn
                rounded
                dark
                class="px-16"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="submit"
            >
                前往繳費
            </v-btn>
            &emsp;
            <v-btn
                rounded
                outlined
                class="px-16"
                color="var(--color-primary)"
                to="/"
                @click="clearData"
            >
                離開
            </v-btn>
        </div>
        <form
            ref="MPG"
            :action="newebpay.url"
            method="post"
        >
            <input
                v-for="(value, key) in newebpay.data"
                :key="key"
                :name="key"
                type="hidden"
                :value="value"
            >
        </form>
    </v-card>
</template>

<script>
import jwt from 'jsonwebtoken';
import { mapActions, mapState } from 'vuex';

export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['serial'],
    data() {
        return {
            competitionId: '',
            formData: {},
            newebpay: {},
        };
    },
    computed: {
        ...mapState('mCompetition', ['competitionList']),
        ...mapState('mActivity', ['activityData']),
        competitionData() {
            return this.competitionList?.find((item) => item.id === this.competitionId) || {};
        },
    },
    mounted() {
        jwt.verify(
            localStorage.getItem('formData'),
            process.env.VUE_APP_TOKEN_KEY,
            (err, decoded) => {
                // token 過期 or 解密錯誤：移除 token 並要求重新查詢
                if (err) localStorage.removeItem('formData');
                else {
                    this.competitionId = decoded.competitionId;
                    this.formData = decoded.data;
                }
            },
        );
    },
    methods: {
        ...mapActions('mApply', ['GenerateMpg']),
        submit() {
            this.GenerateMpg(this.serial).then((res) => {
                this.newebpay = res.data;
                this.$nextTick(() => {
                    this.$refs.MPG.submit();
                });
            });
        },
        clearData() {
            localStorage.removeItem('formData');
        },
    },
};
</script>

<style lang="scss" scoped>
th {
    font-size: initial !important;
}
</style>

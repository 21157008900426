<template>
    <v-card>
        <div style="background: var(--color-secondary); color: #fff">
            <v-card-title class="text-h5 text-center justify-center font-weight-bold">
                {{ $route.meta.subtitle }}
            </v-card-title>
        </div>
        <div class="pa-5 pa-sm-10">
            <v-simple-table>
                <tbody>
                    <tr>
                        <td>姓名</td>
                        <td>{{ personalData.name }}</td>
                    </tr>
                    <tr>
                        <td>報考項目</td>
                        <td>
                            <template v-if="personalData.competition">
                                {{ newName?newName:personalData.competition.name }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td>繳費狀況</td>
                        <td>
                            <template v-if="personalData.paid">
                                <b>已繳費</b>
                            </template>
                            <template v-else>
                                <b style="color: var(--color-highlight)">未繳費</b>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <b>繳費期限 {{ activityData.payDeadline | formatDate }} 截止</b>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <br>
            <br>
            <v-row justify="center">
                <v-col
                    sm="4"
                    lg="3"
                >
                    <v-btn
                        dark
                        rounded
                        outlined
                        class="rwd-12"
                        color="var(--color-primary)"
                        @click="$router.go(-1)"
                    >
                        回上頁
                    </v-btn>
                </v-col>
                <v-col
                    v-if="!personalData.paid && serial !== 'error'"
                    sm="4"
                    lg="3"
                >
                    <v-btn
                        dark
                        rounded
                        class="rwd-12"
                        :style="{
                            background: 'var(--color-primary-gradient)',
                        }"
                        @click="submit"
                    >
                        按此線上繳費
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <form
            ref="MPG"
            :action="newebpay.url"
            method="post"
        >
            <input
                v-for="(value, key) in newebpay.data"
                :key="key"
                :name="key"
                type="hidden"
                :value="value"
            >
        </form>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['serial'],
    data() {
        return {
            newebpay: {},
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        ...mapState('mApply', ['personalData', 'newName']),
    },
    methods: {
        ...mapActions('mApply', ['GenerateMpg']),
        submit() {
            this.GenerateMpg(this.serial).then((res) => {
                this.newebpay = res.data;
                this.$nextTick(() => {
                    this.$refs.MPG.submit();
                });
            });
        },
    },
};
</script>

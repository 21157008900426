<template>
    <div
        class="d-flex flex-column align-center pa-3 pd-sm-5 my-sm-10"
        style="max-width: 1000px; margin: auto; line-height: 1.7"
    >
        <h2>退費辦法</h2>
        <br>
        <template v-if="settings['退費辦法']">
            <pre>{{ settings['退費辦法'] }}</pre>
        </template>
        <template v-else>
            <ol>
                <li>
                    報名成功後至繳費截止日因故無法參加能力競賽者，可由電話申請退費，並請同時提供欲退款帳戶。
                </li>
                <li>
                    報名截止日至考試前15日申請退費者，另收行政手續費$200，餘款退至所提供帳戶。
                </li>
                <li>
                    考試前14日起因已進入考試準備作業，恕不接受退費。但有下列情況者例外：
                    <ul style="list-style-type: disc">
                        <li>參賽者本人發生重大傷病時。</li>
                        <li>參賽者直系親屬因故傷亡時。</li>
                        <li>因遇颱風、地震、豪雨等不可抗拒之天災時。</li>
                    </ul>
                    符合以上條件而無法參賽者，得檢附書面證明申請退費。
                </li>
                <li>
                    主辦單位將配合政府防疫政策，視疫情狀況考慮延期或取消競賽。若延期舉行，將另行公布延後日期與不克參加的退費辦法；若取消競賽，將會全額退款。
                </li>
            </ol>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('mSetting', ['settings']),
    },
};
</script>

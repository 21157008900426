<template>
    <!-- <section
        class="chart"
        style="
            overflow: auto;
            width: 100%;
            padding: 30px;
            border-radius: 20px;
            background: var(--var-background-darkblue-rgb);
        "
    >
        <div style="min-width: 700px">
        </div>
    </section> -->
    <div>
        <canvas ref="myChart" />
        <img
            ref="ChartImg"
            style="display: none"
        >
    </div>
</template>

<script lang="ts">
import Chartjs from 'chart.js/auto';
import Vue from 'vue';

export default Vue.extend({
    props: {
        chartData: {
            type: Object,
            default: () => ({}),
        },
        chartName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            desktopMode: false,
        };
    },
    mounted() {
        this.init(this.$refs.myChart);
    },
    methods: {
        init(el) {
            if (el) {
                const drawLabelsPlugin = {
                    id: 'drawLabels',
                    afterDraw: (chart) => {
                        const { ctx, chartArea, data } = chart;
                        const { labels } = data;

                        ctx.save();
                        ctx.font = '12px Arial';
                        ctx.fillStyle = 'black';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';

                        const centerX = chartArea.left + (chartArea.right - chartArea.left) / 2;
                        const centerY = chartArea.top + (chartArea.bottom - chartArea.top) / 2;
                        const radius = Math.min(
                            chartArea.right - centerX,
                            chartArea.bottom - centerY,
                        );

                        if (labels) {
                            labels.forEach((label, i) => {
                                const angle = (i * Math.PI * 2) / labels.length - Math.PI / 2;
                                const x = centerX + (radius + 20) * Math.cos(angle);
                                const y = centerY + (radius + 20) * Math.sin(angle);
                                ctx.fillText(label, x, y);
                            });
                        }

                        ctx.restore();
                    },
                };
                // eslint-disable-next-line no-new
                new Chartjs(el, {
                    type: 'radar',
                    options: {
                        responsive: true,
                        scales: {
                            r: {
                                pointLabels: {
                                    display: false,
                                },
                                suggestedMin: 0,
                                suggestedMax: 100,
                            },
                        },
                        layout: {
                            autoPadding: true,
                            padding: {
                                top: 50,
                                right: 50,
                                bottom: 50,
                                left: 50,
                            },
                        },
                        interaction: {
                            mode: 'index',
                            intersect: false,
                            axis: 'x',
                        },
                        animation: {
                            onComplete: () => {
                                const image = el.toDataURL('image/png');
                                this.$refs.ChartImg.src = image;
                                this.$emit('getChartImg', { name: this.chartName, img: image });
                            },
                        },
                        plugins: {
                            legend: {
                                position: 'bottom',
                            },
                        },
                    },
                    data: this.chartData,
                    plugins: [drawLabelsPlugin],
                });
            }
        },
    },
});
</script>

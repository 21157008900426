import API from '@/api';
import * as utils from '../utils';

export default {
    namespaced: true,
    state: {
    },
    actions: {
        BannerList() {
            return API.Carousel.PublicList()
                .catch(utils.handleAPIError);
        },
        ScheduleList(__, params) {
            return API.Schedule.PublicList({ params })
                .catch(utils.handleAPIError);
        },
    },
};

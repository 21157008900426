<template>
    <v-list dark>
        <v-list-item>
            <b>Menu</b>
        </v-list-item>
        <v-divider />
        <template v-for="(item, i) in data">
            <v-list-group
                v-if="item.children"
                :key="i"
                color="#ffb71b"
                :style="{ background: item.color }"
            >
                <template v-slot:activator>
                    <v-list-item-title>
                        <span>{{ item.title }}</span>
                    </v-list-item-title>
                </template>
                <template v-for="(subitem, j) in item.children">
                    <v-list-item
                        :key="j"
                        :to="subitem.goto"
                        link
                    >
                        <template #default="{ active }">
                            <v-list-item-title>
                                <span :class="{ active }">{{ subitem.title }}</span>
                            </v-list-item-title>
                        </template>
                    </v-list-item>
                </template>
            </v-list-group>
            <v-list-item
                v-else-if="item.goto"
                :key="i"
                :to="item.goto"
                link
                dark
            >
                <template #default="{ active }">
                    <v-list-item-title>
                        <span :class="{ active }">{{ item.title }}</span>
                    </v-list-item-title>
                </template>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.active {
    color: #ffb71b;
}
</style>

import API from '@/api';
import * as utils from '../utils';

export default {
    namespaced: true,
    state: {
        competitionList: [],
    },
    actions: {
        List({ state }, params) {
            return API.Competition.PublicList({ params })
                .then((res) => {
                    state.competitionList = res.data.raw;
                    return res;
                })
                .catch(utils.handleAPIError);
        },
    },
};

<template>
    <div
        class="d-flex"
        style="height: 100%"
    >
        <template v-for="(item, i) in data">
            <v-menu
                v-if="item.children"
                :key="i"
                offset-y
                tile
                open-on-hover
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        tile
                        large
                        depressed
                        style="height: 100%"
                        :dark="!!item.color"
                        :color="item.color"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ item.title }}
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list
                    color="#757575"
                    dark
                    dense
                    class="py-0"
                >
                    <template v-for="(subitem, j) in item.children">
                        <v-list-item
                            :key="j"
                            :link="!!subitem.goto"
                            :to="subitem.goto"
                            dense
                            active-class="router-active"
                        >
                            {{ subitem.title }}
                        </v-list-item>
                    </template>
                </v-list>
            </v-menu>
            <v-btn
                v-else
                :key="i"
                :to="item.goto"
                :dark="!!item.color"
                :color="item.color"
                :text="!item.color"
                tile
                large
                depressed
                style="height: 100%"
            >
                {{ item.title }}
            </v-btn>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.router-active {
    background: var(--color-primary);
}
</style>

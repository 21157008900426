<template>
    <v-card>
        <!-- <textarea
            :value="JSON.stringify(scoreData, null, 4)"
            readonly
            style="width: 100%; height: 500px; overflow: auto;"
        /> -->
        <template v-if="hash === 'error'">
            <div class="d-flex flex-column align-center pa-5 pa-sm-10">
                <p class="text-center">
                    查無成績
                </p>
                <br>
                <v-btn
                    dark
                    rounded
                    outlined
                    color="var(--color-primary)"
                    @click="$router.go(-1)"
                >
                    回上頁
                </v-btn>
            </div>
        </template>
        <template v-else>
            <div style="background: var(--color-secondary); color: #fff">
                <v-card-title class="text-h5 text-center justify-center font-weight-bold">
                    {{ $route.meta.subtitle }}
                </v-card-title>
            </div>
            <div
                v-if="scoreData.score"
                class="pa-5 pa-sm-10"
            >
                <v-simple-table
                    class="rounded-lg"
                    style="overflow: hidden; border: 2px solid #e2e2e2"
                >
                    <tbody>
                        <tr class="d-sm-none">
                            <th>姓名</th>
                            <td colspan="3">
                                {{ scoreData.name }}
                            </td>
                        </tr>
                        <tr class="d-sm-none">
                            <th>准考證號碼</th>
                            <td colspan="3">
                                {{ scoreData.admitCardId }}
                            </td>
                        </tr>
                        <tr class="d-none d-sm-table-row">
                            <th>姓名</th>
                            <td>{{ scoreData.name }}</td>
                            <th>准考證號碼</th>
                            <td>{{ scoreData.admitCardId }}</td>
                        </tr>
                        <tr>
                            <th>測驗日期</th>
                            <td colspan="3">
                                {{ scoreData.date | formatDate }}
                            </td>
                        </tr>
                        <tr>
                            <th>報考項目</th>
                            <td colspan="3">
                                {{ scoreData.competitionName }}
                            </td>
                        </tr>
                        <tr>
                            <th>成績<br>(滿分100分)</th>
                            <td colspan="3">
                                {{ scoreData.score }}
                            </td>
                        </tr>
                        <tr>
                            <th>獎項</th>
                            <td colspan="3">
                                {{ scoreData.prize }}
                            </td>
                        </tr>
                        <tr class="d-none d-sm-table-row">
                            <th>排名</th>
                            <td>{{ scoreData.rank }}</td>
                            <th>PR值</th>
                            <td>{{ scoreData.percentileRank }}</td>
                        </tr>
                        <tr class="d-sm-none">
                            <th>PR值</th>
                            <td colspan="3">
                                {{ scoreData.percentileRank }}
                            </td>
                        </tr>
                        <tr class="d-sm-none">
                            <th>排名</th>
                            <td colspan="3">
                                {{ scoreData.rank }}
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <br>
                <h2 class="title-type1">
                    各科雷達圖
                </h2>
                <br>
                <div
                    v-for="(item, i) in subjects"
                    :key="i"
                >
                    <h3 class="title-type2">
                        {{ item.name }}
                    </h3>
                    <v-row
                        align="center"
                        class="my-0"
                    >
                        <v-col
                            cols="12"
                            sm="6"
                            style="position: relative;"
                        >
                            <Chart
                                :key="scoreData.id"
                                :chart-data="item.chartData"
                                :chart-name="item.name"
                                :chart-img-array="chartImgArray"
                                @getChartImg="getChartImg"
                            />
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                        >
                            <p class="suggestion py-5">
                                <b>分析建議:</b>
                                <pre>{{ item.analysis }}</pre>
                            </p>
                        </v-col>
                    </v-row>
                </div>
                <br>
                <div class="d-flex align-center justify-space-between">
                    <h2 class="title-type1">
                        {{ scoreData.prize?'得獎證書':'參賽證明' }}
                    </h2>
                    <v-btn
                        small
                        outlined
                        color="var(--color-primary)"
                        :disabled="!scoreData.certificationTemplate"
                        @click="downloadCertification()"
                    >
                        {{ scoreData.prize?'下載數位證書':'下載數位證明' }}
                        <span class="material-icons-outlined md-18">file_download</span>
                    </v-btn>
                </div>
                <br>
                <!-- <img
                    src="/images/證書.jpg"
                    width="100%"
                > -->
                <img
                    :src="scoreData.certificationTemplate || ''"
                    width="100%"
                >

                <br>
                <h4
                    class="text-center"
                    style="color: var(--color-primary)"
                >
                    如果有學業上的需求及建議，歡迎撥打協會專線洽詢。
                </h4>
                <br>
                <v-btn
                    dark
                    rounded
                    class="d-block ma-auto px-10"
                    :style="{
                        background: 'var(--color-primary-gradient)',
                    }"
                    @click="$router.go(-1)"
                >
                    回上頁
                </v-btn>
            </div>
            <div
                v-else
                class="d-flex justify-center align-center pa-5 pa-sm-10"
            >
                <p class="text-center">
                    查無成績
                </p>
            </div>
        </template>
    </v-card>
</template>

<script>
import Chart from '@/components/Chart.vue';
import { downloadFile } from '@/utils/methods';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        Chart,
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['searchID', 'hash', 'activity'],
    data() {
        return {
            chartImgArray: [],
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        ...mapState('mApply', ['scoreData']),
        subjects() {
            return this.scoreData?.subjects?.map((item) => ({
                ...item,
                chartData: {
                    labels: item.labels,
                    datasets: [
                        {
                            ...item.datasets[0],
                            label: '個人答對率(%)',
                            fill: true,
                            backgroundColor: 'rgba(0, 195, 194, 0.06)',
                            borderColor: 'rgb(0, 195, 194)',
                            pointBackgroundColor: 'rgb(0, 195, 194)',
                            pointBorderColor: '#fff',
                        },
                        {
                            ...item.datasets[1],
                            label: '全體學生平均(%)',
                            fill: true,
                            backgroundColor: 'rgba(255, 203, 52, 0.15)',
                            borderColor: 'rgb(255, 203, 52)',
                            pointBackgroundColor: 'rgb(255, 203, 52)',
                            pointBorderColor: '#fff',
                        },
                    ],
                },
            })) || [];
        },
    },
    watch: {
        activityId() {
            this.handleFetch();
        },
    },
    created() {
        this.handleFetch();
    },
    methods: {
        ...mapActions([
            'mApply/scoreQuery',
            'mApply/certificationQuery',
        ]),
        handleFetch() {
            if (this.hash !== 'error') {
                if (this.activity && this.hash) {
                    this['mApply/scoreQuery']({
                        activity: this.activity,
                        hash: this.hash,
                    })
                        .then(() => {
                            this['mApply/certificationQuery']({
                                activity: this.activity,
                                hash: this.hash,
                            });
                        });
                }
            }
        },
        downloadCertification() {
            if (this.activity && this.hash) {
                downloadFile(`${this.scoreData.prize ? `得獎證書_${this.scoreData.name}.pdf` : `參賽證明_${this.scoreData.name}.pdf`}`, () => this['mApply/certificationQuery']({
                    activity: this.activity,
                    hash: this.hash,
                    pdf: true,
                }));
            }
        },
        downloadProve() {
            if (this.activity && this.hash) {
                downloadFile('我的證明.pdf', () => this['mApply/certificationQuery']({
                    activity: this.activity,
                    hash: this.hash,
                    pdf: true,
                }));
            }
        },
        getChartImg(data) {
            this.chartImgArray.push(data);
        },
    },
};
</script>

<style lang="scss" scoped>
tr {
    &:not(:last-child) {
        th,
        td {
            border-bottom: 2px solid #e2e2e2 !important;
        }
    }
}

th {
    text-align: center !important;
    white-space: nowrap;
    background: #30c1a8;
    color: #fff;
}

.title-type1 {
    display: flex;
    align-items: center;

    &::before {
        content: "";
        display: block;
        width: 4px;
        height: 1em;
        background: var(--color-primary);
        margin-right: 10px;
    }
}

.title-type2 {
    background: var(--color-primary);
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
}

.suggestion {
    border-top: 1px solid #00a084;
    border-bottom: 1px solid #00a084;
}
</style>

import API from '@/api';
import * as utils from '../utils';

export default {
    namespaced: true,
    state: {},
    actions: {
        QuestionList(__, params) {
            return API.Question.PublicList({ params })
                .catch(utils.handleAPIError);
        },
    },
};

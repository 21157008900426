import API from '@/api';
import * as utils from '../utils';

const home = {
    namespaced: true,
    state: {},
    actions: {
        List(__, params) {
            return API.PostExam.PublicList({ params }).catch(utils.handleAPIError);
        },
    },
};

export default home;

import _ from 'lodash';
import { Notification } from 'element-ui';
// import notification from 'ant-design-vue/es/notification';

export const handleAPISuccess = (message, description) => {
    Notification.success({
        title: message,
        message: description,
    });
    // notification.success({
    //     message,
    //     description,
    // });
};

export const notifyError = (e) => {
    Notification.error({
        title: '錯誤',
        message: _.get(e, 'response.data.message', e.message || e.toString()),
    });
    // notification.error({
    //     message: '錯誤',
    //     description: _.get(e, 'response.data.message', e.message || e.toString()),
    // });
};

export const handleAPIError = (e) => {
    notifyError(e);
    throw e;
};

export const deepTransformId = (ret) => {
    try {
        const raw = JSON.stringify(ret);
        return JSON.parse(raw.replace(/"_id"/g, '"id"'));
    } catch {
        throw new Error('deep transform id fail');
    }
};

<!-- eslint-disable max-len -->
<template>
    <v-card>
        <div style="background: var(--color-secondary); color: #fff">
            <v-card-title class="text-h5 text-center justify-center font-weight-bold">
                {{ $route.meta.subtitle }}
            </v-card-title>
        </div>
        <div class="pa-5 pa-sm-10">
            <div
                v-if="$route.name === 'GroupGradesInquiryList'"
                class="d-flex justify-end"
            >
                <v-btn
                    outlined
                    color="var(--color-primary)"
                    :disabled="list.length === 0 ? true : false"
                    @click="downloadCardList()"
                >
                    匯出所有資料
                    <span class="material-icons-outlined md-18">file_download</span>
                </v-btn>
            </div>
            <v-data-table
                :items="list"
                :headers="[
                    { text: '姓名', value: 'name', width: '80'},
                    { text: '活動', value: 'activity', width: '200' },
                    { text: '報考項目', value: 'competition.name', width: '200' },
                    { text: '准考證號碼', value: 'admitCardId', width: '150' },
                    { text: '', value: 'actions', sortable: false, width: '50' },
                ]"
                :items-per-page="10"
            >
                <template v-slot:item.activity="{ item }">
                    <div>{{ activityNames[item.activity] }}</div>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        small
                        outlined
                        color="var(--color-primary)"
                        class="px-5"
                        :to="`/${nextRoute}/grades-inquiries/${searchID}/${item.hash || 'error'}/${
                            item.activity
                        }`"
                    >
                        查看成績
                    </v-btn>
                </template>
            </v-data-table>
            <br>
            <v-btn
                dark
                rounded
                class="d-block ma-auto px-10"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="$router.go(-1)"
            >
                回上頁
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { downloadFile } from '@/utils/methods';
import jwt from 'jsonwebtoken';

export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['searchID'],
    data() {
        return {
            paginationOptions: {
                page: 1,
                itemsPerPage: 10,
            },
            activityNames: {},
        };
    },
    computed: {
        ...mapState('mApply', ['groupData', 'historyData']),
        total() {
            if (this.$route.path.includes('group')) return this.groupData.total;
            if (this.$route.path.includes('history')) return this.historyData.total;
            return 0;
        },
        list() {
            if (this.$route.path.includes('group')) return this.groupData.raw;
            if (this.$route.path.includes('history')) return this.historyData.raw;
            return [];
        },
        nextRoute() {
            if (this.$route.path.includes('group')) return 'group';
            if (this.$route.path.includes('history')) return 'history';
            return '';
        },
    },
    methods: {
        ...mapActions(['mApply/groupScoreDownload', 'mActivity/getName']),
        downloadCardList() {
            const decodeData = jwt.decode(localStorage.getItem(process.env.VUE_APP_TOKEN_KEY));
            downloadFile('團體成績.xlsx', () => this['mApply/groupScoreDownload'](decodeData));
        },
        async fetchActivityName(id) {
            try {
                const result = await this['mActivity/getName'](id);
                this.$set(this.activityNames, id, result.data.name);
            } catch (error) {
                console.error(error);
                this.$set(this.activityNames, id, 'Error');
            }
        },
    },
    watch: {
        list: {
            immediate: true,
            handler(newList) {
                if (newList) {
                    const uniqueActivityIds = [...new Set(newList.map((item) => item.activity))];
                    uniqueActivityIds.forEach((id) => {
                        if (!this.activityNames[id]) {
                            this.fetchActivityName(id);
                        }
                    });
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/mixins/dashed-border.scss";
.text-overflow-ellipsis {
    @include text-overflow-ellipsis(1);
}
</style>

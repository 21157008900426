<template>
    <v-card class="pa-5 pa-sm-10">
        <h2 class="text-center">
            請再次確認資料是否無誤
        </h2>
        <br>
        <br>
        <v-simple-table>
            <tbody>
                <tr>
                    <th>報考項目</th>
                    <td>{{ competitionData.name }}</td>
                </tr>
                <tr>
                    <th>考場選擇</th>
                    <td>{{ formData.place }}</td>
                </tr>
                <tr>
                    <th>姓名</th>
                    <td>{{ formData.name }}</td>
                </tr>
                <tr>
                    <th>性別</th>
                    <td>{{ formData.gender }}</td>
                </tr>
                <tr>
                    <th>身份證字號</th>
                    <td>{{ formData.identity }}</td>
                </tr>
                <tr>
                    <th>出生日期</th>
                    <td>
                        民國{{ formData.birthYear }}年 {{ formData.birthMonth }}月
                        {{ formData.birthDay }}日
                    </td>
                </tr>
                <tr>
                    <th>就讀學校</th>
                    <td>
                        {{ formData.city }}
                        {{ formData.school }}
                    </td>
                </tr>
                <tr>
                    <th>班級</th>
                    <td>{{ formData.grade }}年 {{ formData.class }}班</td>
                </tr>
                <tr>
                    <th>家長姓名</th>
                    <td>{{ formData.parentName }}</td>
                </tr>
                <tr>
                    <th>手機</th>
                    <td>{{ formData.cellphone }}</td>
                </tr>
                <tr>
                    <th>家用電話</th>
                    <td>{{ formData.telephone }}</td>
                </tr>
                <tr>
                    <th style="line-height: 1.2;">
                        通訊地址
                        <br><span style="font-size: 12px;">(寄送准考證、贈品及試後成績單所用)</span>
                    </th>
                    <td
                        colspan="2"
                        style="padding: 10px 16px;"
                    >
                        {{ formData.postalCode }} {{ formData.county }} {{ formData.address }}
                    </td>
                </tr>
                <tr>
                    <th>備註</th>
                    <td>
                        <pre>{{ formData.remark }}</pre>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <div
            v-if="errorMessage"
            class="d-flex justify-center"
        >
            <v-alert type="error">
                {{ errorMessage }}
                <br>請您前往「查詢與下載」→「個人報名資料及繳費查詢」查詢已報名資料。
            </v-alert>
        </div>
        <div class="d-flex justify-center">
            <v-btn
                rounded
                dark
                class="px-16"
                :style="{
                    background: 'var(--color-primary-gradient)',
                }"
                @click="submit"
            >
                確認送出
            </v-btn>
            &emsp;
            <v-btn
                rounded
                outlined
                class="px-16"
                color="var(--color-primary)"
                to="/registration/personal"
            >
                回上頁
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import jwt from 'jsonwebtoken';
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            errorMessage: '',
            competitionId: '',
            formData: {},
        };
    },
    computed: {
        ...mapState('mCompetition', ['competitionList']),
        competitionData() {
            return this.competitionList?.find((item) => item.id === this.competitionId) || {};
        },
    },
    mounted() {
        jwt.verify(
            localStorage.getItem('formData'),
            process.env.VUE_APP_TOKEN_KEY,
            (err, decoded) => {
                // token 過期 or 解密錯誤：移除 token 並要求重新查詢
                if (err) localStorage.removeItem('formData');
                else {
                    this.competitionId = decoded.competitionId;
                    this.formData = decoded.data;
                }
            },
        );
    },
    methods: {
        ...mapActions('mApply', ['personalApply']),
        submit() {
            this.personalApply({
                id: this.competitionId,
                data: this.formData,
            }).then((res) => {
                this.$router.push(`/registration/personal/success/${res.data?.serial}`);
            })
                .catch((err) => {
                    this.errorMessage = err.response.data.message;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
th {
    font-size: initial !important;
    white-space: nowrap;
}
</style>

<template>
    <div>
        <v-app-bar
            app
            dense
            shrink-on-scroll
            scroll-threshold="500"
            :height="breakpoints.laptop ? 80 : 50"
        >
            <router-link
                to="/"
                class="py-2"
                style="height: 100%; max-height: 70px; margin: auto"
            >
                <img
                    src="@/assets/logo.png"
                    style="height: 100%"
                >
            </router-link>
            <v-spacer />
            <template v-if="breakpoints.laptop">
                <Menu :data="list" />
            </template>
            <v-btn
                v-else
                tile
                depressed
                style="height:100%;"
                @click="drawerVisible = !drawerVisible"
            >
                <v-icon>mdi-menu</v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawerVisible"
            fixed
            app
            dark
            right
            temporary
            width="100%"
            color="#2F2F2F"
        >
            <div class="d-flex">
                <v-spacer />
                <v-btn
                    icon
                    depressed
                    class="mt-3"
                    @click="drawerVisible = false"
                >
                    <v-icon size="30px">
                        mdi-close
                    </v-icon>
                </v-btn>
            </div>
            <MenuMobile :data="list" />
        </v-navigation-drawer>
    </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import MenuMobile from '@/components/MenuMobile.vue';
import ResizeObserver from '@/utils/ResizeObserver';
import { mapState } from 'vuex';

export default {
    components: {
        Menu,
        MenuMobile,
    },
    data() {
        return {
            items: [
                { title: 'Dashboard', icon: 'mdi-view-dashboard' },
                { title: 'Account', icon: 'mdi-account-box' },
                { title: 'Admin', icon: 'mdi-gavel' },
            ],
            drawerVisible: false,
            breakpoints: {
                mobile: false,
                tablet: false,
                laptop: false,
                desktop: false,
            },
        };
    },
    computed: {
        ...mapState('mActivity', ['activityData']),
        list() {
            return [
                {
                    title: '關於NEST',
                    goto: '/about',
                    children: [
                        {
                            title: '關於NEST',
                            goto: '/about',
                        },
                        {
                            title: this.activityData?.title || `${new Date().getFullYear()}活動辦法`,
                            goto: '/regulations',
                        },
                        {
                            title: '成績報告書',
                            goto: '/report',
                        },
                    ],
                },
                {
                    title: this.activityData?.title || `${new Date().getFullYear()}活動辦法`,
                    goto: '/regulations',
                },
                {
                    title: '競賽須知',
                    goto: '/competition-notice',
                },
                {
                    title: '查詢與下載',
                    children: [
                        {
                            title: '個人報名資料及繳費查詢',
                            goto: '/personal',
                        },
                        {
                            title: '個人成績查詢',
                            goto: '/history',
                        },
                        {
                            title: '團體報名資料及團體成績查詢',
                            goto: '/group',
                        },
                        {
                            title: '整體分數統計數據',
                            goto: '/statistics',
                        },
                        {
                            title: '歷屆試題查詢',
                            goto: '/history-exam',
                        },
                    ],
                },
                {
                    title: '常見問題',
                    goto: '/about',
                    children: [
                        {
                            title: '賽事資訊',
                            goto: '/question/賽事資訊',
                        },
                        {
                            title: '考試內容',
                            goto: '/question/考試內容',
                        },
                        {
                            title: '聯絡NEST辦公室',
                            goto: '/contact',
                        },
                    ],
                },
                {
                    title: '線上報名',
                    color: 'var(--color-primary)',
                    children: [
                        {
                            title: '個人報名',
                            goto: '/registration/personal',
                        },
                        {
                            title: '團體報名',
                            goto: '/registration/group',
                        },
                    ],
                },
            ];
        },
    },
    mounted() {
        const sm = 426;
        const md = 769;
        const lg = 1025;
        ResizeObserver(document.body, {
            onResizing: ({ width }) => {
                this.breakpoints.mobile = width < sm;
                this.breakpoints.tablet = width >= sm;
                this.breakpoints.laptop = width >= md;
                this.breakpoints.desktop = width >= lg;
            },
        });
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && this.drawerVisible) {
                this.drawerVisible = false;
            }
        });
    },
};
</script>

<template>
    <!-- Slider main container -->
    <div class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
            <!-- Slides -->
            <template v-for="(item, i) in data">
                <div
                    :key="i"
                    class="swiper-slide"
                >
                    <slot
                        :slide="item"
                        :index="i"
                    />
                </div>
            </template>
        </div>

        <!-- If we need pagination -->
        <!-- <div class="swiper-pagination" /> -->

        <!-- If we need navigation buttons -->
        <div class="swiper-button-prev" />
        <div class="swiper-button-next" />
    </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css';

export default {
    props: {
        data: {
            type: [Number, Array],
            required: true,
        },
    },
    data() {
        return {};
    },
    mounted() {
        // eslint-disable-next-line no-unused-vars
        const swiper = new Swiper('.swiper', {
            // configure Swiper to use modules
            modules: [Navigation, Pagination, Autoplay],
            slidesPerView: 'auto',
            speed: 1000,
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true,
            // },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true,
            autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
            },
            mousewheel: true,
            grabCursor: true,
            centeredSlides: true,
        });
    },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
    width: 80%;
}
.swiper-button-prev,
.swiper-button-next {
    color: #fff;
}

@media screen and (min-width: 960px) {
    .swiper-slide {
        width: 33.33333%;
    }
}
</style>

<template>
    <div>
        <v-card class="pa-5 pa-sm-10 ma-auto">
            <v-simple-table>
                <tbody>
                    <tr
                        v-for="(item, i) in list"
                        :key="i"
                    >
                        <th>
                            <h2>{{ item.title }}</h2>
                        </th>
                        <td>
                            <v-btn
                                depressed
                                dark
                                color="var(--color-secondary)"
                                class="px-10"
                                :to="item.to"
                            >
                                查看
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
        <br>
        <v-btn
            outlined
            rounded
            color="var(--color-primary)"
            class="d-block ma-auto px-10"
            @click="reSearch()"
        >
            重新查詢
        </v-btn>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['searchID'],
    computed: {
        list() {
            return [
                { title: '考試資訊及准考證號碼查詢', to: `/group/admit-card-list/${this.searchID}` },
                { title: '成績查詢', to: `/group/grades-inquiry-list/${this.searchID}` },
            ];
        },
    },
    methods: {
        reSearch() {
            localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
            this.$router.replace('/group');
        },
    },
};
</script>

<style lang="scss" scoped>
td:last-child {
    text-align: right;
}
</style>

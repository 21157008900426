import moment from 'moment';
import FileSaver from 'file-saver';
import * as utils from '@/store/utils';
import { jsPDF as JSPDF } from 'jspdf';

export function generateNumberArray(start = 0, end = 0) {
    const arr = [];
    for (let i = start; i <= end; i += 1) {
        arr.push(i);
    }
    return arr;
}

export function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
}

export function getNowChineseYear() {
    const year = moment().format('YYYY');
    // eslint-disable-next-line radix
    return parseInt(year) - 1911;
}

export function downloadFile(filename, callback) {
    return callback()
        .then((res) => {
            FileSaver.saveAs(res.data, filename);
            return Promise.resolve(res);
        })
        .catch(utils.handleAPIError);
}
export function downloadJPEGAsPDF(filename, callback) {
    return callback()
        .then((res) => {
            const pdfFilename = filename.endsWith('.pdf') ? filename : `${filename}.pdf`;
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;
                    const pdf = new JSPDF({
                        orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
                        unit: 'pt',
                        format: [1700, 1202],
                    });

                    pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);
                    pdf.save(pdfFilename);
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(res.data);

            return Promise.resolve(res);
        })
        .catch(utils.handleAPIError);
}

<template>
    <v-card style="padding: 10%">
        <h2 class="text-center">
            繳費{{ result }}
        </h2>
    </v-card>
</template>

<script>
export default {
    computed: {
        result() {
            return this.$route.query.status?.toLowerCase() === 'success' ? '成功' : '失敗';
        },
    },
    created() {
        localStorage.removeItem('formData');
    },
};
</script>

import jwt from 'jsonwebtoken';
import router from '../router';

router.beforeEach(async (to, __, next) => {
    const { matched, params } = to;

    if (localStorage.getItem(process.env.VUE_APP_TOKEN_KEY)) {
        jwt.verify(
            localStorage.getItem(process.env.VUE_APP_TOKEN_KEY),
            process.env.VUE_APP_TOKEN_KEY,
            (err, decoded) => {
                // token 過期 or 解密錯誤：移除 token 並要求重新查詢
                if (err) localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
                else if (
                    params.searchID && params.searchID !== decoded.searchID
                ) {
                    localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
                }
            },
        );
    }

    if (matched.some(({ meta }) => meta.permission)) {
        if (localStorage.getItem(process.env.VUE_APP_TOKEN_KEY)) {
            next();
        } else if (to.name !== 'PersonalSearch' && matched.some(({ name }) => name === 'Personal')) {
            console.log('redirect to PersonalSearch');
            next({ name: 'PersonalSearch' });
        } else if (to.name !== 'GroupSearch' && matched.some(({ name }) => name === 'Group')) {
            console.log('redirect to GroupSearch');
            next({ name: 'GroupSearch' });
        } else if (to.name !== 'HistorySearch' && matched.some(({ name }) => name === 'History')) {
            console.log('redirect to HistorySearch');
            next({ name: 'HistorySearch' });
        } else next('/');
    } else {
        next();
    }
});
